.swiper {
  width: 100%;
  height: 100%;
}

.swiper-pagination{
    position: relative;
    margin-top: 20px;
}

.swiper-pagination-bullet{
      border: 1px solid #792B7C;
      background-color: #fff;
      opacity: 1;
}

.swiper-pagination-bullet-active
{
    background-color: #792B7C;
}

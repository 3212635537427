@font-face {
  font-family: 'Myriad Pro Bold';
  src: local('MYRIADPRO-SEMIBOLD.woff'), url('./MYRIADPRO-SEMIBOLD.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro Light';
  src: local('MyriadPro-Light.woff'), url('./MyriadPro-Light.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro Regular';
  src: local('MYRIADPRO-REGULAR.woff'), url('./MYRIADPRO-REGULAR.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy Bold', sans-serif;
  src: local('Gilroy-ExtraBold.ttf'), url('./Gilroy-ExtraBold.ttf') format('ttf');
}

.vertical-timeline::before {
    width: 8px;
    border-radius: 20px;
}

.vertical-timeline-element-icon {
    box-shadow: none;
    border: 2px solid #792B7C;
    background-color: #fff;
}

.vertical-timeline-element-content {
    background: #fff;
    box-shadow: none;
    color :  #792B7C;
}

.logo-icon{
    width: 50px;
    height: 50px;
}

.vertical-timeline-element-content  .vertical-timeline-element-date {
    padding: 0 ;
    opacity: 1;
}

.vertical-timeline--two-columns .vertical-timeline-element-content {
    padding: 0 0;
}

.vertical-timeline::before {
    width: 18px;
    border-radius: 20px;
}
.vertical-timeline.vertical-timeline--two-columns:before {
    margin-left: -8px;
}


@media only screen and (min-width: 1170px)
{
    .vertical-timeline--two-columns .vertical-timeline-element-icon {
        width: 50px;
        height: 50px;
        left: 50%;
        margin-left: -25px;
    }
    .vertical-timeline--two-columns .vertical-timeline-element-content{
        /* padding: 0 1.5em; */
    }
    .vertical-timeline.vertical-timeline--two-columns {
        width: 100%;
    }
    .vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 40px;
    height: 40px;
    margin-left: -20px;
}
}

@media only screen and (max-width: 1170px) {
    .logo-icon{
        display: none;
    }
}
